class ZIDXAccountListings implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingsContainer";
    }
    getMatchURL(){
        return "/account/listings";
    }
    render(){
        // console.log("listings");
    }
}